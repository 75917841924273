<template>
  <div>
    <!-- Main Row -->
    <div class="row">
      <div class="col-md-12">
        <Card :title="'Detail Laporan Tindakan Medis'">
          <template v-slot:body>
            <div class="row align-items-center">
              <!-- Profile Image -->
              <div class="col-md-4">
                <div
                  class="d-flex justify-content-center align-items-center image-container"
                >
                  <div style="height: 350px; width: 350px; position: relative">
                    <img
                      class="image"
                      src="/images/appointment-splash.svg"
                      @load="onImageLoad()"
                    />
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div class="col" v-if="imgLoaded != false">
                <table class="table mb-3">
                  <tr>
                    <td><strong>Tanggal</strong></td>
                    <td>{{ data.date_display }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nama Pasien</strong></td>
                    <td>{{ data.patient_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Umur</strong></td>
                    <td>{{ getAge(data.patient_age_display) }}</td>
                  </tr>
                  <tr>
                    <td><strong>Diagnosa Tindakan</strong></td>
                    <td>
                      <ul
                        v-for="action in data.action_type_display"
                        :key="action.id"
                      >
                        <li class="ml-5">{{ action.name }}</li>
                      </ul>
                      <span v-if="data.action_type_display.length < 1">-</span>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Operator</strong></td>
                    <td>
                      <ul
                        v-for="operator in data.operator_display"
                        :key="operator.id"
                      >
                        <li class="ml-5">{{ operator.name }}</li>
                      </ul>
                      <span v-if="data.operator_display.length < 1">-</span>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Asisten</strong></td>
                    <td>
                      <ul
                        v-for="assistant in data.assistant_display"
                        :key="assistant.id"
                      >
                        <li class="ml-5">{{ assistant.name }}</li>
                      </ul>
                      <span v-if="data.assistant_display.length < 1">-</span>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Catatan Perawat</strong></td>
                    <td>{{ data.nurse_notes }}</td>
                  </tr>
                  <tr>
                    <td><strong>Anastesi</strong></td>
                    <td>{{ data.anesteshia }}</td>
                  </tr>
                  <tr>
                    <td><strong>Dokter Anastesi</strong></td>
                    <td>
                      <ul
                        v-for="anesthetist in data.anesthetist_display"
                        :key="anesthetist.id"
                      >
                        <li class="ml-5">{{ anesthetist.name }}</li>
                      </ul>
                      <span v-if="data.anesthetist_display.length < 1">-</span>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Dokter Penanggaung Jawab</strong></td>
                    <td>
                      <ul
                        v-for="responsible_doctor in data.responsible_doctor_display"
                        :key="responsible_doctor.id"
                      >
                        <li class="ml-5">{{ responsible_doctor.name }}</li>
                      </ul>
                      <span v-if="data.responsible_doctor_display.length < 1"
                        >-</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Obat/Alkes</strong></td>
                    <td>
                      <ul
                        v-for="medicines in data.medicines_display"
                        :key="medicines.id"
                      >
                        <li class="ml-5">
                          {{ medicines.name + " (" + medicines.quantity + ")" }}
                        </li>
                      </ul>
                      <span v-if="data.medicines_display.length < 1">-</span>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Inventori</strong></td>
                    <td>
                      <ul
                        v-for="inventory in data.inventory_display"
                        :key="inventory.id"
                      >
                        <li class="ml-5">
                          {{ inventory.name + " (" + inventory.quantity + ")" }}
                          <!-- {{ inventory.name + " (" + inventory.quantity + " " + inventory.uom + ")" }} -->
                        </li>
                      </ul>
                      <span v-if="data.inventory_display.length < 1">-</span>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Lampiran</strong></td>
                    <td v-if="data.attachment_list">
                      <ul
                        v-for="(attach, index) in attachment_list_display"
                        :key="attach.id"
                      >
                        <li>
                          <a
                            target="_blank"
                            :href="attachment_list_display[index]"
                            >{{ file_name[index] }}</a
                          >
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Keterangan</strong></td>
                    <td>{{ data.notes ? data.notes : "-" }}</td>
                  </tr>
                </table>

                <!-- <button
                  @click="
                    $router.push({
                      name: 'medical-actions-edit',
                      params: { id: data.id },
                    })
                  "
                  v-if="manipulateBtn == true"
                  class="btn mx-1 btn-success"
                >
                  <i class="far fa-fw fa-edit"></i> Edit
                </button> -->

                <button
                  class="btn mx-1 btn-danger"
                  v-if="manipulateBtn == true"
                  @click="deleteData"
                >
                  <i class="far fa-fw fa-trash-alt"></i> Hapus
                </button>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Detail",

  components: {
    Card,
  },

  data() {
    return {
      imgLoaded: false,
      data: [],
      attachment_list: [],
      attachment_list_display: [],
      file_name: [],
      manipulateBtn: false,
    };
  },

  methods: {
    onImageLoad() {
      this.imgLoaded = true;
    },

    async deleteData() {
      let result = await module.delete(
        "medical-actions/" + this.$route.params.id
      );
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push("/medical-actions/list");
      }
    },

    async get() {
      this.data = await module.get("medical-actions/" + this.$route.params.id);
      // this.attachment_list = this.data.attachment_list.split(",");
      this.attachment_list = this.data.attachment_list.split(",");

      // let b
      // for(b = 0; b < this.attachment_list.)

      let date = new Date(this.data.date);
      let convert = date.toLocaleString().split(",")[0];
      this.data.date = convert;

      let a;
      for (a = 0; a < this.attachment_list.length; a++) {
        if (this.attachment_list[a]) {
          this.attachment_list_display.push(this.attachment_list[a]);

          let name = this.attachment_list[a].split("____")[1];
          name = name.replace("_", " ");
          this.file_name.push(name);
        }
      }

      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push("/medical-actions/list");
      }
    },

    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1202") {
          this.manipulateBtn = true;
        }
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laporan Tindakan Medis", route: "/medical-actions" },
      { title: "Detail Laporan Tindakan Medis" },
    ]);

    this.get();
    this.setActiveMenu();
  },
};
</script>

<style>
/* Dynamic Size Image */
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>